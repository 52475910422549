<template>
  <div class="root">
    {{ isDateBeforePrevMonday ? $t("planDuration.startedDate") : $t("planDuration.startingDate") }}:
    <template v-if="showDatepicker">
      <date-picker
        v-if="showDatepicker"
        ref="startingDatePicker"
        v-model="datepickerValue"
        :lang="{ formatLocale: { firstDayOfWeek: 1 } }"
        :clearable="false"
        class="start-datepicker"
      />
      <a href="#" class="datepicker-link font-bold" @click.prevent="openDatepickerPopup">{{ startDate }}</a>
      <EventsAndDaysOff v-if="getPlanConfigValue('isEventBasedPlan')" />
    </template>
    <b v-else>{{ startDate }}</b>
    <template v-if="!getPlanConfigValue('isEventBasedPlan')">
      <template v-if="!isDateBeforePrevMonday">
        <template v-if="isThisMonday"> ({{ $t("planDuration.thisMonday") }}) </template>
        <template v-else-if="isFutureMonday"> ({{ $t("planDuration.futureMonday") }}) </template>
        <template v-else> ({{ $t("planDuration.nextMonday") }}) </template>
      </template>
      <template v-else-if="isPrevMonday"> ({{ $t("planDuration.prevMonday") }}) </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import moment from "moment"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import { DATE_FORMAT } from "@/types"
import EventsAndDaysOff from "@/components/plan/EventsAndDaysOff.vue"
import getStartDate from "@/services/getStartDate"

export default {
  name: "StartingDate",
  components: {
    EventsAndDaysOff,
    DatePicker
  },
  props: {
    showDatepicker: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("plan", ["getPlanConfigValue", "firstPrimaryEvent", "getTotalWeeksFromDates"]),

    datepickerValue: {
      get() {
        return moment(this.getPlanConfigValue("startDate")).toDate()
      },
      set(value) {
        this.updatePlanConfigValue({ name: "startDate", value: moment(getStartDate(value)).format(DATE_FORMAT) })
      }
    },
    nextMonday() {
      return moment()
        .startOf("isoWeek")
        .add(1, "week")
    },
    thisMonday() {
      return moment().startOf("isoWeek")
    },
    prevMonday() {
      return moment()
        .weekday(-1)
        .startOf("isoWeek")
    },
    isPrevMonday() {
      return moment(this.startDate).isSame(this.prevMonday)
    },
    isThisMonday() {
      return moment(this.startDate).isSame(this.thisMonday)
    },
    isNextMonday() {
      return moment(this.startDate).isSame(this.nextMonday)
    },
    isFutureMonday() {
      return !this.isDateBeforePrevMonday && !this.isThisMonday && !this.isNextMonday
    },
    isDateBeforePrevMonday() {
      return moment(this.startDate).isSameOrBefore(this.prevMonday)
    },
    isDateAfterThisMonday() {
      return moment(this.startDate).isAfter(this.thisMonday)
    },
    startDate() {
      return this.getPlanConfigValue("startDate")
    }
  },
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue"]),
    ...mapMutations(["setProfileFactsValue"]),

    openDatepickerPopup() {
      this.$refs.startingDatePicker.openPopup()
    }
  }
}
</script>
<style lang="scss" scoped>
.datepicker-link {
  border-bottom: 1px dashed;
  margin-right: 2px;
  &:hover {
    border-bottom: unset;
  }
}

.start-datepicker {
  width: 0;
  visibility: hidden;
}
</style>
